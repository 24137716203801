const logoDefaultUrl = require('@/assets/images/logo-kiennast-full.png');
const logoGreenUrl = require('@/assets/images/logo-kiennast-full.png');

const cartCreditDefaultUrl = require('../assets/images/icon-credit-card@3x.png');
const cartCreditGreenUrl = require('../assets/images/icon-credit-card@3x.png');

const cartClientDefaultUrl = require('../assets/images/icon-client-card@3x.png');
const cartClientGreenUrl = require('../assets/images/icon-client-card@3x.png');

const outdoorPaymentApp = require('../assets/images/icon-payment-app.png'); // icon-payment-app.png
const outdoorPaymentSms = require('../assets/images/icon-payment-sms.png'); // icon-payment-sms.png
const outdoorPaymentCreditCard = require('../assets/images/icon-payment-creditcard.png'); // icon-payment-creditcard.png
const outdoorPaymentNfc = require('../assets/images/icon-payment-nfc.png'); // icon-payment-nfc.png

export default {
  iLogo: {
    defaultUrl: logoDefaultUrl,
    greenUrl: logoGreenUrl,
  },
  iCartCredit: {
    defaultUrl: cartCreditDefaultUrl,
    greenUrl: cartCreditGreenUrl,
  },
  iCartClient: {
    defaultUrl: cartClientDefaultUrl,
    greenUrl: cartClientGreenUrl,
  },
  iPaymentApp: {
    defaultUrl: outdoorPaymentApp,
  },
  iPaymentSms: {
    defaultUrl: outdoorPaymentSms,
  },
  iPaymentCC: {
    defaultUrl: outdoorPaymentCreditCard,
  },
  iPaymentNfc: {
    defaultUrl: outdoorPaymentNfc,
  },
};
